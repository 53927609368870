import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import styles from './clothing-shop.module.scss';
import appStyles from 'styles.module.scss';
import { PageHeader, Spin, Card, Empty, Button, Row, Col, Progress, Statistic } from 'antd';
import { useUser } from 'hooks/use-user';
import { useHistory, useRouteMatch, Switch, Route } from 'react-router';
import { CreateClothingShop } from './create/create-clothing-shop';
import moment from 'moment';
import { ClothingShopDetail, User } from './detail/clothing-shop-details';
import { firestore } from 'config/firebase/firebase';

export interface ClothingShopEvent {
    id: string
    date?: firebase.firestore.Timestamp
    registerHelper?: firebase.firestore.Timestamp
    registerSeller?: firebase.firestore.Timestamp
    kleiderboerseText?: string
    maxSeller: number
}

export const ClothingShopView = () => {
    const user = useUser();
    const { push } = useHistory();

    const [loading, setLoading] = useState(true);
    const [shops, setShops] = useState<ClothingShopEvent[]>([]);

    const uid = user.appUser?.firebaseUser.uid;

    useEffect(() => {
        setLoading(true);
        return firestore().collection('kleiderboerse').onSnapshot(snap => {
            setLoading(false);
            const shopsLoaded = snap.docs.flatMap(doc => {
                return {
                    ...doc.data() as ClothingShopEvent,
                    id: doc.id
                } as any;
            });
            shopsLoaded.sort((a: ClothingShopEvent,b: ClothingShopEvent) => a.date && b.date ? b.date.toDate().getTime() - a.date.toDate().getTime() : 0)
            setShops(shopsLoaded);
        });
    }, [uid]);

    const { path, url } = useRouteMatch();
    // const { pathname } = useLocation();

    // const isNestedRoute = pathname.lastIndexOf('/') > 0;

    return <div className={classNames(appStyles['flex-grower'], appStyles['center-container-stretch'])}>
        {/* <PageHeader title='Kleiderbörsen' onBack={!isNestedRoute ? undefined : () => {
            const trimmedPath = pathname.endsWith('/') ? pathname.substring(0, pathname.length - 1) : pathname;
            const parentPath = trimmedPath.substring(0, trimmedPath.lastIndexOf('/'));
            push(parentPath);
        }}></PageHeader>
        <div className={classNames(appStyles['flex-grower'], appStyles['center-container-stretch'])}> */}
            <Switch>
                <Route path={`${path}/new`} component={CreateClothingShop} />
                <Route path={`${path}/:shopId`} component={ClothingShopDetail} />
                <Route exact path={path}>
                    <PageHeader title='Kinderkleiderbörsen' subTitle='Übersicht'></PageHeader>
                    <div className={classNames(appStyles['flex-grower'], appStyles['center-container-stretch'])}>
                    {
                        loading ?
                            <Spin tip='Lade Kinderkleiderbörsen...' />
                            :
                            <div className={classNames(appStyles['flex-grower'], appStyles['center-container-stretch'])}>
                                <Button type='primary' size='large' onClick={() => push(`${url}/new`)}>Neue Kleiderbörse erstellen</Button>
                                
                                {
                                    shops.length <= 0 ?
                                    <div className={classNames(appStyles['flex-grower'], appStyles['center-container-stretch'])}>
                                        <Empty description='Keine Kinderkleiderbörsen vorhanden'>
                                            <Button type='primary' onClick={() => push(`${url}/new`)}>Kleiderbörse erstellen</Button>
                                        </Empty>
                                    </div>
                                    :
                                    <div className={classNames(appStyles['flex-grower'])} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'stretch'}}>
                                        <Row className={classNames(styles['row'])}>
                                            {
                                                shops.map(shop => <Col key={shop.id} xs={24} md={12} lg={8} xxl={6}>
                                                    <ClothingShopOverviewCard shop={shop} />
                                                </Col>
                                                )
                                            }
                                        </Row>
                                    </div>
                                }
                            </div>
                    }
                    </div>
                </Route>
            </Switch>
        {/* </div> */}
    </div>
}

const ClothingShopOverviewCard = (props: { shop: ClothingShopEvent }) => {
    const { shop } = props;

    const { push } = useHistory();
    const { url } = useRouteMatch();

    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState<User[]>([]);

    useEffect(() => {
        return firestore().collection('kleiderboerse').doc(shop.id).collection('user').onSnapshot(snap => {
            setUsers(snap.docs.map(doc => ({ ...doc.data(), id: doc.id } as User)));
            setLoading(false)
        });
    }, [shop.id]);

    const sellers = users.filter(user => !!user.seller);
    const helpers = users.filter(user => !!user.helper);

    const progress = (100 / (shop?.maxSeller || 1)) * sellers.length;

    return <Card
        title={`Kinderkleiderbörse ${shop.date ? moment(shop.date?.toDate()).format('l') : 'Kein Datum'}`}
        actions={[ <Button onClick={() => push(`${url}/${shop.id}`)}>Öffnen</Button> ]}
        className={classNames(styles['card'])}
        loading={loading}
    >
        <div className={classNames(appStyles['flex-grower'], appStyles['center-container'])} style={{ flexDirection: 'row', paddingLeft: '5%', paddingRight: '5%', paddingBottom: '2%' }}>
            <Statistic title='Teilnehmer total' value={users.length}  />
            <div className={classNames(appStyles['flex-grower'])} />
            <Statistic title='Helfer' value={helpers.length}  />
        </div>
        <div className={classNames(appStyles['flex-grower'], appStyles['center-container'])} style={{ flexDirection: 'row', paddingLeft: '5%', paddingRight: '5%' }}>
            <Statistic title='Verkäufer' value={sellers.length} suffix={'/ '+shop.maxSeller}  />
            <div className={classNames(appStyles['flex-grower'])} />
            <Progress
                percent={progress.toFixed(2) as any}
                type='circle'
                status={progress > 100 ? 'exception' : progress === 100 ? 'success' : 'active'}
                size='small'
            />
        </div>
    </Card>
}