import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import appStyles from 'styles.module.scss';
import { ClothingShopEvent } from 'components/clothing-shop/clothing-shop';
import { Spin, Empty, Row, Col, Card, Button, Result, Typography, Input, Alert, Form } from 'antd';
import { firestore, functions } from 'config/firebase/firebase';
import moment from 'moment';
import { Switch, Route, useHistory, useRouteMatch, useParams } from 'react-router';
import { RegisterForm } from './register-form';

export const LateRegister = () => {
    const [shops, setShops] = useState<ClothingShopEvent[]>([]);
    const [loading, setLoading] = useState(true);

    const { push } = useHistory();
    const { path, url } = useRouteMatch();

    useEffect(() => {
        (async () => {
            const snap = await firestore().collection('kleiderboerse').get();
            const shopsLoaded = snap.docs.map(doc => ({ ...doc.data() as any, id: doc.id }));
            shopsLoaded.sort((a: ClothingShopEvent,b: ClothingShopEvent) => a.date && b.date ? b.date.toDate().getTime() - a.date.toDate().getTime() : 0)
            setShops(shopsLoaded);
            setLoading(false);
        })()
    })

    return loading ?
        <div className={classNames(appStyles['flex-grower'], appStyles['center-container'])}><Spin tip='Lade Formular' /></div>
        :
        <div className={classNames(appStyles['flex-grower'], appStyles['center-container-stretch'])}>
            <Switch>
                <Route path={`${path}/:shopId`} component={LateRegisterFormWrapper} />
                <Route>
                    {
                        shops.length <= 0 ?
                        <div className={classNames(appStyles['flex-grower'], appStyles['center-container-stretch'])}>
                            <Empty description='Keine Kinderkleiderbörsen vorhanden' />
                        </div>
                        :
                        <div className={classNames(appStyles['flex-grower'])} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'stretch'}}>
                            <Row style={{ width: '100%' }}>
                                {
                                    shops.map(shop => <Col key={shop.id} xs={24} md={12}>
                                        <Card
                                            title={`Kinderkleiderbörse ${shop.date ? moment(shop.date?.toDate()).format('l') : 'Kein Datum'}`}
                                            style={{ margin: '2%' }}
                                        >
                                            <Button
                                                onClick={() => push(`${url}/${shop.id}`)}
                                                type='primary'
                                                block
                                            >
                                                Registrieren
                                            </Button>
                                        </Card>
                                    </Col>
                                    )
                                }
                            </Row>
                        </div>
                    }
                </Route>
            </Switch>
        </div>
}

export const LateRegisterFormWrapper = () => {
    const { shopId } = useParams();

    const [shop, setShop] = useState<ClothingShopEvent | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            const shopDoc = await firestore().collection('kleiderboerse').doc(shopId).get();
            if(shopDoc.exists) {
                setShop({
                    ...shopDoc.data() as any,
                    id: shopDoc.id
                })
            } else {
                setShop(null);
            }

            setLoading(false);
        })()
    }, [shopId])

    // this is the one from the text field
    const [passwordEntered, setPasswordEntered] = useState<string>('');
    const [verificationLoading, setVerificationLoading] = useState(false);
    // this is the one that is verified to work
    const [verifiedPassword, setVerifiedPassword] = useState<string | null>(null);
    const [message, setMessage] = useState<{ message: string, type: 'success' | 'error' | 'warning' | 'info'} | null>(null)

    const validatePassword = async (currentPassword: string) => {
        console.log(currentPassword);
        setVerificationLoading(true);
        try {
            await functions().httpsCallable('verifyLateRegisterPassword')({
                shopId: shopId,
                password: currentPassword
            });

            setVerifiedPassword(currentPassword);
            setMessage({ message: 'Erfolgreich verifiziert.', type: 'success' });
        } catch(err) {
            setMessage({ message: (err.message === 'INTERNAL' ? undefined : err.message) || 'Ein unerwarteter Fehler ist aufgetreten.', type: 'error' });
        }
        setVerificationLoading(false);
    }

    return loading ?
        /* Loading indicator */
        <div className={classNames(appStyles['flex-grower'], appStyles['center-container'])}>
            <Spin tip='Lade Spätanmeldung' />
        </div>
        :
        !shop ?
            /* shop not found */
            <Result
                title='Kinderkleiderbörse nicht gefunden'
                status='404'
            />
            :
            !verifiedPassword ?
                /* password not yet verified */
                <div className={classNames(appStyles['flex-grower'], appStyles['center-container'])}>
                    { !!message && <Alert {...message} showIcon style={{ width: '100%', marginBottom: '14px' }} /> }
                    <Typography.Title level={2}>{ `Spätanmeldung Kinderkleiderbörse ${shop.date ? moment(shop.date?.toDate()).format('l') : 'Kein Datum'}` }</Typography.Title>
                    <Form.Item style={{ width: '100%' }}>
                        <Input type='password'
                            size='large'
                            value={passwordEntered}
                            onChange={e => setPasswordEntered(e.currentTarget.value)}
                            placeholder='Spätanmeldungspasswort eingeben'
                            disabled={verificationLoading}
                        />
                    </Form.Item>
                    <Form.Item style={{ width: '100%' }}>
                        <Button
                            type='primary'
                            block
                            onClick={() => validatePassword(passwordEntered)}
                            disabled={verificationLoading}
                            loading={verificationLoading}
                        >
                            Passwort überprüfen
                        </Button>
                    </Form.Item>
                </div>
                :
                /* password verified, actual form input */
                <div className={classNames(appStyles['flex-grower'], appStyles['center-container-stretch'])}>
                    <RegisterForm
                        {...{ shop: shop, lateRegisterPassword: verifiedPassword } as any}
                    />
                </div>
}