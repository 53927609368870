import React, { useMemo } from 'react';
import { PDFDownloadLink, StyleSheet, View, Text, Page, Document } from '@react-pdf/renderer';
import { ClothingShopExportProps } from './clothing-shop-excel';
import { User } from './clothing-shop-details';
import { Button } from 'antd';

interface LabelViewProps {
    users: User[]
    columns: number
    rows: number
}

const styles = StyleSheet.create({
    page: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
        padding: '5px'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    label: {
        width: '70mm',
        height: '38.6mm', // drucker 12mm / 8 = 1.5 == 371. + 1.5 === 28.6
        padding: '2mm'
    },
    text: {
        textAlign: 'center',
        fontSize: '12pt'
    }
});

const LabelInner = (props: { user: User } ) => {
    const { user } = props;

    return <View style={styles.label} key={user.id}>
        <Text style={styles.text} key={user.id + '-number'}>{user.sellerNumber || ' '}</Text>
        <Text style={styles.text} key={user.id + '-name'}>{user.prename + ' ' + user.surename}</Text>
        <Text style={styles.text} key={user.id + '-street'}>{user.street}</Text>
        <Text style={styles.text} key={user.id + '-city'}>{user.city}</Text>
    </View>
}

const LabelView = (props: LabelViewProps) => {
    const { users, columns, rows } = props;

    const document = useMemo(() => (docUsers: User[], docColumns: number, docRows: number) => {
        const document: JSX.Element[] = [];
        let pageContent: JSX.Element[] = [];
        let rowContent: JSX.Element[] = [];
        
        let rowCount = 0;
        let columnCount = 0;
        docUsers.forEach(user => {
            // add label to row
            rowContent.push(<LabelInner key={`user-${user.id}`} user={user} />);
            columnCount++;

            // if row is full, add it to page
            if(columnCount >= docColumns) {
                pageContent.push(
                    <View key={`row-${document.length}-${pageContent.length}`} style={styles.row}>
                        { rowContent }
                    </View>
                );
                rowContent = [];
                columnCount = 0;
                rowCount++;
            }

            // if page is full, add it to doc
            if(rowCount >= docRows) {
                document.push(
                    <Page size='A4' style={styles.page} key={`page-${document.length}`}>
                        { pageContent }
                    </Page>
                )

                pageContent = [];
                rowCount = 0;
            }
        });

        // if there are columns left, add them to the last row
        if(columnCount > 0) {
            pageContent.push(
                <View key={`row-${document.length}-${pageContent.length}`} style={styles.row}>
                    { rowContent }
                </View>
            );
            rowContent = [];
            columnCount = 0;
            rowCount++;
        }

        // if there are rows left, add them to the last page
        if(rowCount > 0) {
            document.push(
                <Page size='A4' style={styles.page} key={`page-${document.length}`}>
                    { pageContent }
                </Page>
            )
        }

        return document
    }, [])

    return <Document key='etiketten-list'>
        { document(users, columns, rows) }
    </Document>

}

export const ClothingShopLabelExport = (props: ClothingShopExportProps) => {
    const { shop, users } = props;

    const usersPrepared = users.filter(user => !!user.seller).sort((a, b) => (a.sellerNumber || 0) - (b.sellerNumber || 0));

    return <PDFDownloadLink
        document={
            <LabelView
                users={usersPrepared} 
                columns={3}
                rows={8}
            />
        }
        fileName={`Kleiderbörse_${shop.date ? `${shop.date.toDate().getDate()}_${shop.date.toDate().getMonth()+1}_${shop.date.toDate().getFullYear()}` : 'invalid_date'}_Etiketten`}
    >
        {({ blob, url, loading, error }) => (
            <Button
                icon='file'
                style={{ width: '100%' }}
                loading={loading}
                disabled={loading}
            >
                Verkäufer Etiketten
            </Button>
        )}
    </PDFDownloadLink>
}