import React, { useState, useEffect } from 'react';
import styles from './App.module.scss';
import appStyles from 'styles.module.scss';
import classNames from 'classnames';
import { Menu, Layout, Typography, Icon, Spin, Result, Button } from 'antd';
import { AppRoutes, HomeRoute } from 'routes';
import { useLocation, useHistory, Route, Switch } from 'react-router';
import { useUser } from 'hooks/use-user';
import { auth } from 'config/firebase/firebase';
import { useDispatch } from 'react-redux';
import { setUser } from 'config/redux/user/user';

const { Header, Content, Footer, Sider } = Layout;

const App: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { pathname } = useLocation();
  const { push, replace } = useHistory();

  const user = useUser();
  const dispatch = useDispatch();

  const logout = async () => {
    await auth().signOut()
    replace('/')
  }

  useEffect(() => {
    return auth().onAuthStateChanged(user => {
      dispatch(setUser(user));
    })
  }, [dispatch])

  const filteredRoutes = AppRoutes.filter(appRoute => {
    // When the user is not signed in, return the public access
    if(!user.appUser) return appRoute.public;
    // if the route is not available on login, do not make it available
    if(!appRoute.private) return false;
    // if the route requires admin, only make it available if the user is admin
    if(appRoute.adminOnly) return user.appUser.admin;

    return appRoute.private;
  });

  // key is the base url, we exclude / from the initial search because it would match everything
  const matchedRoute = filteredRoutes.find(appRoute => pathname.startsWith(appRoute.key));
  const matchedKey = matchedRoute ? matchedRoute.key : '/';

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        theme='dark'
        collapsible
        collapsed={collapsed}
        onCollapse={setCollapsed}
        breakpoint='lg'
        >
        <Header className={classNames(styles['app-logo-container'])} onClick={() => push('/')}>
          <Typography.Title ellipsis className={classNames(styles['app-logo'])}>{ collapsed ? 'K' : 'Kleiderbörse'}</Typography.Title>
        </Header>
        <Menu
          mode='inline'
          theme='dark'
          selectedKeys={[matchedKey]}
          selectable={false}
          >
            {
              user.loading ?
                <Menu.Item><Icon type='loading' /><span>Lade...</span></Menu.Item>
                :
                [HomeRoute, ...filteredRoutes].map(appRoute => <Menu.Item key={`${appRoute.key}`} onClick={() => { push(appRoute.key) }}>
                  {appRoute.icon}
                  <span>{appRoute.displayName}</span>
                </Menu.Item>)
            }
            {
              !!user.appUser && <Menu.Item onClick={() => logout()} key='logout' className={classNames(styles['logout'])}><Icon type='logout' /><span>Abmelden</span></Menu.Item>
            }
        </Menu>
      </Sider>
      <Layout>
          <Content style={{ margin: '0 16px' }} className={classNames(appStyles['center-container-stretch'])}>
            { 
              user.loading ?
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Spin tip='Lade...'></Spin>
                </div>
                :
                <Switch>
                  {[HomeRoute, ...filteredRoutes].map(appRoute => <Route key={appRoute.key} path={appRoute.key} exact={appRoute.key === '/'} component={appRoute.view}></Route>)}
                  <Route>
                    <Result
                      status='404'
                      title='Seite nicht gefunden'
                      // subTitle='Du must angemeldet sein, um das Dashboard benutzen zu können.'
                      extra={[
                          <Button
                              key='homepage'
                              type='primary'
                              onClick={() => push('/')}
                          >Zurück zur Startseite</Button>
                      ]}
                    >
                    </Result>
                  </Route>
                </Switch>
            }
          </Content>
          <Footer style={{ textAlign: 'center' }}>
                Designed by <a target="_blank" rel="noopener noreferrer" href="https://softwulf.ch">Soft Wulf GmbH</a> &copy; Familien Verein Urdorf {(new Date()).getFullYear()}
            </Footer>
        </Layout>
    </Layout>
  );
}

export default App;
