import * as firebaseRaw from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';

const firebase = firebaseRaw.initializeApp({ 
    apiKey: 'AIzaSyCG1U_O-C1HRFmkbnmXi2gWF_05SJfshvw',
    authDomain: 'kleiderboerse-urdorf.firebaseapp.com',
    databaseURL: 'https://kleiderboerse-urdorf.firebaseio.com',
    projectId: 'kleiderboerse-urdorf',
    storageBucket: 'kleiderboerse-urdorf.appspot.com',
    messagingSenderId: '1032483322093'
 });

const firestoreInstance = firebase.firestore();
const authInstance = firebase.auth();
const functionsInstance = firebase.functions();

export const firestore = () => firestoreInstance;
export const auth = () => authInstance;
export const functions = () => functionsInstance;

export const firestoreRaw = firebaseRaw.firestore;
export const authRaw = firebaseRaw.auth;
export const functionsRaw = firebaseRaw.functions;