import React, { useState } from 'react';
import { useHistory } from 'react-router';
import Form, { FormComponentProps } from 'antd/lib/form';
import moment from 'moment';
import { firestore } from 'config/firebase/firebase';
import { Alert, DatePicker, InputNumber, Button, Input, Popconfirm, Modal } from 'antd';
import locale from 'antd/es/date-picker/locale/de_DE';
import TextArea from 'antd/lib/input/TextArea';

export interface EditClothingShopProps extends FormComponentProps {
    shopId?: string
    date: moment.Moment
    helperDate: moment.Moment
    sellerDate: moment.Moment
    maxSeller: number
    postRegisterKey: string
    emailText: string
    onSave?: () => any
    onDelete?: () => any
}

const EditClothingShopImpl = (props: EditClothingShopProps) => {
    const { push } = useHistory();
    const { form } = props;

    const [loading, setLoading] = useState(false);
    const [emailPreview, setEmailPreview] = useState(false);
    const [emailText, setEmailText] = useState<string | undefined>(props.emailText);
    const [message, setMessage] = useState<{ message: string, type: 'success' | 'error' | 'warning' | 'info'} | null>(null)

    const storePostRegisterKey = async (shopId: string, key: string) => {
        if(!!key) {
            await firestore().collection('secrets').doc(shopId).set({
                postRegisterKey: key
            })
        } else {
            await firestore().collection('secrets').doc(shopId).delete()
        }
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        form.validateFields(async (err, values: EditClothingShopProps) => {
            if(!err) {
                setLoading(true);

                try {
                    const registerHelper: Date  = values.helperDate.toDate();
                    const registerSeller: Date = values.sellerDate.toDate();

                    registerHelper.setHours(0);
                    registerHelper.setMinutes(0);
                    registerHelper.setSeconds(0);
                    registerHelper.setMilliseconds(0);

                    registerSeller.setHours(0);
                    registerSeller.setMinutes(0);
                    registerSeller.setSeconds(0);
                    registerSeller.setMilliseconds(0);

                    if(props.shopId) {
                        await firestore().collection('kleiderboerse').doc(props.shopId).update({
                            date: values.date.toDate(),
                            registerHelper,
                            registerSeller,
                            emailText: emailText ? emailText : '',
                            maxSeller: values.maxSeller
                        })
                        await storePostRegisterKey(props.shopId, values.postRegisterKey);
                        setMessage({ message: 'Kleiderbörse gespeichert', type: 'success' });
                    } else {
                        const doc = await firestore().collection('kleiderboerse').add({
                            date: values.date.toDate(),
                            registerHelper,
                            registerSeller,
                            emailText: emailText ? emailText : '',
                            maxSeller: values.maxSeller,
                        })
                        await storePostRegisterKey(doc.id, values.postRegisterKey);
                        setMessage({ message: 'Kleiderbörse erstellt', type: 'success' });
                        push(`/kleiderboersen/${doc.id}`);
                    }
                } catch(err) {
                    setMessage({ message: err.message || 'Ein unerwarteter Fehler ist aufgetreten.', type: 'error' });
                }

                props.onSave && props.onSave();
                setLoading(false);
            }
        })
    }

    const handleDelete = async (shopId: string) => {
        setLoading(true);

        console.log('deleted');

        try {
            await firestore().collection('secrets').doc(shopId).delete();
            await firestore().collection('kleiderboerse').doc(shopId).delete();
            setMessage({ message: 'Kleiderbörse gelöscht', type: 'success' });
        } catch(err) {
            setMessage({ message: err.message || 'Ein unerwarteter Fehler ist aufgetreten.', type: 'error' });
        }

        props.onDelete && props.onDelete();
        setLoading(false);
    }
    
    const emailPreviewModal = ()=> {
        return <Modal
          title='Email Vorschau'
          onOk={()=>setEmailPreview(false)}
          onCancel={()=>setEmailPreview(false)}
          visible={emailPreview}
        >
            <p>Guten Tag  Max Muster.</p>
            <p>Hiermit bestätigen wir Ihre Anmeldung für die Kleiderbörse am {(form.getFieldValue('date') as moment.Moment) ? ((form.getFieldValue('date') as moment.Moment).format('DD.MM.YYYY')) : moment().format('DD.MM.YYYY')}.</p>
            <p>{emailText ? emailText : ''}</p>
            <p>Vielen Dank für Ihre Anmeldung und bis bald.</p>
            <p>Freundliche Grüsse</p>
            <p></p>
            <p>Familien-Verein Urdorf</p>
            <p><a href="mailto:info@kleiderboerse-urdorf.ch">info@kleiderboerse-urdorf.ch</a></p>
        </Modal>
    }

    const { getFieldDecorator } = props.form;

    const defaultDate = (key: string) => (props as any)[key] ? moment((props as any)[key].toDate()) : undefined;

    return <Form onSubmit={handleSubmit}>
        { !!message && <Alert {...message} showIcon style={{ marginBottom: '14px' }} /> }
        <Form.Item label='Kleiderbörsen Datum'>
            {
                getFieldDecorator('date', {
                    initialValue: defaultDate('date'),
                    rules: [{ required: true, message: 'Bitte gib ein Datum ein.' }],
                })(
                    <DatePicker
                        placeholder='Datum auswählen'
                        size='large'
                        disabled={loading}
                        style={{ width: '100%' }}
                        locale={locale}
                    />
                )
            }
        </Form.Item>
        <Form.Item label='Helfer Datum'>
            {
                getFieldDecorator('helperDate', {
                    initialValue: defaultDate('registerHelper'),
                    rules: [{ required: true, message: 'Bitte gib ein Datum ein.' }]
                })(
                    <DatePicker
                        placeholder='Datum auswählen'
                        size='large'
                        disabled={loading}
                        style={{ width: '100%' }}
                    />
                )
            }
        </Form.Item>
        <Form.Item label='Verkäufer Datum'>
            {
                getFieldDecorator('sellerDate', {
                    initialValue: defaultDate('registerSeller'),
                    rules: [{ required: true, message: 'Bitte gib ein Datum ein.' }]
                })(
                    <DatePicker
                        placeholder='Datum auswählen'
                        size='large'
                        disabled={loading}
                        style={{ width: '100%' }}
                    />
                )
            }
        </Form.Item>
        <Form.Item label='Maximale Anzahl Verkäufer'>
            {
                getFieldDecorator('maxSeller', {
                    initialValue: props.maxSeller,
                    rules: [
                        { required: true, message: 'Bitte gib eine Zahl ein.' },
                        { validator: (rule, value, callback) => {
                            if (value > 0) {
                                return callback();
                            }
                            callback('Die Eingabe muss grösser als 0 sein!');
                        }
                        }
                    ]
                })(
                    <InputNumber
                        placeholder='Anzahl eingeben'
                        size='large'
                        disabled={loading}
                        style={{ width: '100%' }}
                    />
                )
            }
        </Form.Item>
        <Form.Item label='Email Text (optional)'>
            {
                getFieldDecorator('emailText', {
                    initialValue: props.emailText
                })(
                    <>
                        <TextArea
                            rows={4}
                            onChange={(e)=>{setEmailText(e.currentTarget.value)}}
                            placeholder='Email Text wählen'
                            disabled={loading}
                            style={{ width: '100%' }}
                        />
                        <Button onClick={()=>setEmailPreview(true)}>Vorschau</Button>
                    </>
                )
            }
        </Form.Item>
        {emailPreviewModal()}
        <Form.Item label='Nachmeldungspasswort (optional)'>
            {
                getFieldDecorator('postRegisterKey', {
                    initialValue: props.postRegisterKey
                })(
                    <Input
                        type='text'
                        placeholder='Passwort wählen'
                        size='large'
                        disabled={loading}
                        style={{ width: '100%' }}
                    />
                )
            }
        </Form.Item>
        <Form.Item>
            <Button type='primary' size='large' htmlType='submit' loading={loading} block>
                { props.shopId ? 'Bearbeiten' : 'Erstellen'}
            </Button>
        </Form.Item>
        {
            props.shopId && <Form.Item>
                                <Button type='ghost' size='large' htmlType='reset' block onClick={() => form.resetFields()}>
                                    Zurücksetzen
                                </Button>
                            </Form.Item>
        }
        {
            props.shopId && <Form.Item>
                                <Popconfirm
                                    title='Willst du diese Kleiderbörse wirklich löschen?'
                                    onConfirm={() => handleDelete(props.shopId as string)}
                                    okText='Ja'
                                    cancelText='Nein'
                                >
                                    <Button type='danger' size='large' disabled={loading} block>
                                        Löschen
                                    </Button>
                                </Popconfirm>
                            </Form.Item>
        }
    </Form>
}

export const EditClothingShop = Form.create()(EditClothingShopImpl);