import React from 'react';
import classNames from 'classnames';
import appStyles from 'styles.module.scss';
import { useUser } from 'hooks/use-user';
import { useHistory } from 'react-router';
import { PageHeader, Result, Button, Typography } from 'antd';

export const HomeView = () => {
    const user = useUser();
    const { push } = useHistory();

    return <div className={classNames(appStyles['flex-grower'], appStyles['center-container-stretch'])}>
        <PageHeader title='Home'></PageHeader>
        {
            !user.appUser && <div className={classNames(appStyles['flex-grower'], appStyles['center-container'])}>
                <div>
                    <Typography.Title>Kinderkleiderbörse Favu</Typography.Title>
                    <Typography.Paragraph>
                        Dies ist die Webseite der Kinderkleiderbörse Favu.<br />
                        Hier kannst du dich für Kinderkleiderbörsen registrieren.
                    </Typography.Paragraph>
                    <Button block type='primary' onClick={() => push('/register')}>Registrieren</Button>
                </div>
            </div>
        }
        {
            !!user.appUser && <div className={classNames(appStyles['flex-grower'], appStyles['center-container'])}>
                <Result
                    status='success'
                    title='Kinderkleiderbörse Admin Dashboard'
                    subTitle='Du bist angemeldet, du kannst jetzt loslegen.'
                    extra={[
                        <Button
                            key='overview'
                            type='primary'
                            onClick={() => push('/kleiderboersen')}
                        >Zur Kinderkleiderbörsen Übersicht</Button>
                    ]}
                >
                </Result>
            </div>
        }
    </div>
}