import React, { useState } from 'react';
import classNames from 'classnames';
import appStyles from 'styles.module.scss';
import styles from './login.module.scss';
import { PageHeader, Button, Form, Input, Icon, Alert } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { auth } from 'config/firebase/firebase';
import { useHistory } from 'react-router';

interface LoginFormProps extends FormComponentProps {
    email: string;
    password: string;
}

const LoginViewImpl = (props: LoginFormProps) => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState<{ message: string, type: 'success' | 'error' | 'warning' | 'info'} | null>(null)
    const { replace } = useHistory();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const { form } = props;

        form.validateFields(async (err, values) => {
            if(!err) {
                setLoading(true);

                try {
                    await auth().signInWithEmailAndPassword(values.email, values.password);
                    setMessage({ message: 'Erfolgreich angemeldet.', type: 'success' });
                    replace('/');
                } catch(err) {
                    setMessage({ message: err.message || 'Ein unerwarteter Fehler ist aufgetreten.', type: 'error' });
                }

                setLoading(false);
            }
        })
    }

    const { getFieldDecorator } = props.form;

    return <div className={classNames(appStyles['flex-grower'], appStyles['center-container-stretch'])}>
        <PageHeader title='Admin Dashboard anmelden'></PageHeader>
        <div className={classNames(appStyles['flex-grower'], appStyles['center-container-stretch'], styles['login-form'])}>
            <Form onSubmit={handleSubmit}>
                { !!message && <Alert {...message} showIcon style={{ marginBottom: '14px' }} /> }
                <Form.Item>
                    {
                        getFieldDecorator('email', {
                            rules: [{ required: true, message: 'Bitte gib deine Email ein.' }]
                        })(
                            <Input
                                prefix={<Icon type='mail' style={{ color: 'rgba(0,0,0,.25)' }} />}
                                placeholder='Email'
                                size='large'
                                disabled={loading}
                            />
                        )
                    }
                </Form.Item>
                <Form.Item>
                    {
                        getFieldDecorator('password', {
                            rules: [{ required: true, message: 'Bitte gib dein Passwort ein.' }]
                        })(
                            <Input
                                prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
                                type='password'
                                placeholder='Passwort'
                                size='large'
                                disabled={loading}
                            />
                        )
                    }
                </Form.Item>
                <Form.Item>
                    <Button type='primary' size='large' htmlType='submit' style={{ width: '100%' }} loading={loading}>
                        Anmelden
                    </Button>
                </Form.Item>
            </Form>

        </div>
    </div>
}

export const LoginView = Form.create()(LoginViewImpl) as any;