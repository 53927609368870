import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import appStyles from 'styles.module.scss';
import { PageHeader, Result, Button, Spin } from 'antd';
import { firestore } from 'config/firebase/firebase';
import { ClothingShopEvent } from 'components/clothing-shop/clothing-shop';
import {  GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router';
import { LateRegister } from './late-register';
import { RegisterForm } from './register-form';

export const RegisterView = () => {

    const [latestShop, setLatestShop] = useState<ClothingShopEvent | null>(null);
    const [loading, setLoading] = useState(true);

    const { push } = useHistory();
    const { path, url } = useRouteMatch();

    useEffect(() => {
        (async () => {
            const shopsLoaded = await firestore().collection('kleiderboerse').where('date', '>', new Date()).orderBy('date', 'asc').limit(1).get();
            if(shopsLoaded.size > 0 && shopsLoaded.docs[0].exists) {
                setLatestShop({
                    ...shopsLoaded.docs[0].data(),
                    id: shopsLoaded.docs[0].id
                } as any)
            }
            setLoading(false);
        })()
    }, [])

    return <GoogleReCaptchaProvider reCaptchaKey='6LdmCtQUAAAAABiFnH3dPrPzIr7I49nO6hOGpXUA' language='de-CH'>
        <div className={classNames(appStyles['flex-grower'], appStyles['center-container-stretch'])}>
            <PageHeader title='Registrieren'></PageHeader>
            <Switch>
                {/* Late register form */}
                <Route path={`${path}/late`} component={LateRegister} />
                {/* Default Register form */}
                <Route>
                    {
                        loading ?
                            <div className={classNames(appStyles['flex-grower'], appStyles['center-container'])}>
                                <Spin tip='Lade Formular' />
                            </div>
                            :
                            !latestShop ?
                            <div className={classNames(appStyles['flex-grower'], appStyles['center-container'])}>
                                <Result
                                    status='info'
                                    title='Keine Kinderkleiderbörse offen'
                                    subTitle='Zurzeit kannst du dich für keine Kinderkleiderbörse anmelden.'
                                    extra={[
                                        <Button
                                            key='homepage'
                                            type='primary'
                                            href='https://favu.ch'
                                        >Zurück zur Homepage</Button>,
                                        <Button
                                            type='link'
                                            onClick={() => push(`${url}/late`)}
                                        >Ich habe ein Spätanmeldungspasswort</Button>
                                    ]}
                                >
                                </Result>
                            </div>
                            :
                            <RegisterForm
                                {...{ shop: latestShop } as any}
                            />
                    }
                </Route>
            </Switch>
        </div>
    </GoogleReCaptchaProvider>
}