import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import classNames from 'classnames';
import styles from './clothing-shop-details.module.scss';
import appStyles from 'styles.module.scss';
import { Spin, Result, Button, Table, Progress, Statistic, PageHeader, Row, Col, Modal, Form, Input, Icon } from 'antd';
import { ClothingShopEvent } from '../clothing-shop';
import { ClothingShopExcelExport } from './clothing-shop-excel';
import moment from 'moment';
import { ClothingShopLabelExport } from './clothing-shop-labels';
import TextArea from 'antd/lib/input/TextArea';
import { EditClothingShop } from '../edit/edit-clothing-shop';
import { firestore, firestoreRaw } from 'config/firebase/firebase';

export interface User {
    id: string
    city: string
    email: string
    helper: boolean
    newsletter: boolean
    phone: string
    prename: string
    surename: string
    seller: boolean
    sellerNumber?: number
    street: string
}

const numberColumn = (title: string, key: string) => ({
    title,
    dataIndex: key,
    key,
    sorter: (a: any, b: any) => (a[key] || 0) - (b[key] || 0)
})

const stringColumn = (title: string, key: string) => ({
    title,
    dataIndex: key,
    key,
    sorter: (a: any, b: any) => (a[key] || '').localeCompare(b[key] || ''),
    onFilter: (value: string, record: any) => (record[key] || '').indexOf(value) === 0,
})

const booleanColumn = (title: string, key: string) => ({
    title,
    dataIndex: key,
    key,
    sorter: (a: any, b: any) => (a[key] === b[key]) ? 0 : a[key] ? -1 : 1,
    render: (value: boolean) => !!value ? 'Ja' : 'Nein'
})

const isSeller = (user: User) => user.seller;

export const ClothingShopDetail = () => {
    const { shopId } = useParams();

    const [shop, setShop] = useState<ClothingShopEvent | null>(null);
    const [postRegisterKey, setPostRegisterKey] = useState<string | undefined>(undefined);
    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState(true);
    const [showLabelInfo, setShowLabelInfo] = useState(false);

    useEffect(() => {
        let docDone = false;
        let colDone = false;


        const docListener = firestore().collection('kleiderboerse').doc(shopId).onSnapshot(snap => {
            let shopFetched: ClothingShopEvent | null = null;
            if(snap.exists) {
                shopFetched = {
                    ...snap.data(),
                    id: snap.id
                } as any
            }

            setShop(shopFetched);

            docDone = true;
            if(colDone) setLoading(false);
        });
        const collectionListener = firestore().collection('kleiderboerse').doc(shopId).collection('user').onSnapshot(snap => {
            const fetchedUsers: User[] = snap.docs.map(doc => {
                return {
                    ...doc.data(),
                    id: doc.id
                } as any
            })

            setUsers(fetchedUsers);

            docDone = true;
            if(docDone) setLoading(false);
        });

        const secretListener = firestore().collection('secrets').doc(shopId).onSnapshot(snap => {
            if(snap.exists) {
                setPostRegisterKey((snap.data() as any).postRegisterKey)
            } else {
                setPostRegisterKey(undefined)
            }
        })

        return () => {
            docListener();
            collectionListener();
            secretListener();
        }
    }, [shopId])

    const { push } = useHistory();

    const sellers = users.filter(user => !!user.seller);
    const helpers = users.filter(user => !!user.helper);

    const progress = (100 / (shop?.maxSeller || 1)) * sellers.length;

    const [newsletterModalOpen, setNewsletterModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);

    const updateSellerNumber = async (userToUpdate: User) => {
        if(!!userToUpdate.sellerNumber) {
            const sameSellerNumber = users.find(user => {
                // == instead of === because it wouldn't work for numbers for some reasons
                return user.id !== userToUpdate.id && user.sellerNumber == userToUpdate.sellerNumber; // eslint-disable-line eqeqeq
            });
            if(!!sameSellerNumber) {
                throw new Error('Verk. Nr. bereits vergeben.');
            }
        }

        await firestore().collection('kleiderboerse').doc(shop?.id).collection('user').doc(userToUpdate.id).update({
            sellerNumber: !!userToUpdate.sellerNumber ? userToUpdate.sellerNumber : firestoreRaw.FieldValue.delete()
        })
    }

    return <div className={classNames(appStyles['flex-grower'], appStyles['center-container-stretch'])}>
        <PageHeader
            title='Kinderkleiderbörsen'
            subTitle={`Kinderkleiderbörse ${!!shop ? moment(shop?.date?.toDate()).format('l') : ''}`}
            onBack={() => push('/kleiderboersen')}
        >
            {
                !!shop && !loading && <><Row>
                        <Col sm={24} md={6}>
                            <Button
                                style={{ width: '100%' }}
                                icon='setting'
                                onClick={() => setEditModalOpen(true)}
                                type='primary'
                            >
                                Einstellungen
                            </Button>
                        </Col>
                        <Col sm={24} md={6}>
                            <ClothingShopExcelExport shop={shop} users={users} />
                        </Col>
                        <Col sm={24} md={6}>
                            <ClothingShopLabelExport shop={shop} users={users} />
                            <div style={{justifyContent: 'center', display: 'flex', paddingTop: '2%'}}><Icon type="info-circle" style={{color: 'blue'}} onClick={()=>setShowLabelInfo(true)}/></div>
                            <Modal
                                title="Etiketten Details"
                                visible={showLabelInfo}
                                onOk={()=>setShowLabelInfo(false)}
                                onCancel={()=>setShowLabelInfo(false)}
                                >
                                <p><b>Marke:</b> Migros Papeteria</p>
                                <p><b>Name:</b> Selbstklebe-Etiketten</p>
                                <p><b>Format:</b> A4, 3 x 8 Etiketten = 24 Etiketten pro Seite</p>
                                <p><b>Originalgrösse:</b> 70mm x 37mm</p>
                            </Modal>
                        </Col>
                        <Col sm={24} md={6}>
                            <Button
                                style={{ width: '100%' }}
                                icon='mail'
                                onClick={() => setNewsletterModalOpen(true)}
                            >
                                Newsletter Verteiler
                            </Button>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '2%' }}>
                        <Col xs={12} md={4} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                            <Statistic
                                title='Teilnehmer total'
                                value={users.length}
                            />
                        </Col>
                        <Col xs={12} md={4} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                            <Statistic
                                title='Helfer'
                                value={helpers.length}
                            />
                        </Col>
                        <Col xs={24} md={{ span: 6, offset: 10 }} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <div className={classNames(appStyles['flex-grower'])}>
                                <Statistic
                                    title='Verkäufer'
                                    value={sellers.length}
                                    suffix={'/ '+shop.maxSeller}
                                />
                                <Progress
                                    percent={progress}
                                    type='line'
                                    status={progress > 100 ? 'exception' : progress === 100 ? 'success' : 'active'}
                                    size='default'
                                />
                            </div>
                        </Col>
                    </Row>
                </>
            }
            <Modal
                title='Newsletter Verteiler'
                visible={newsletterModalOpen}
                afterClose={() => setNewsletterModalOpen(false)}
                onOk={() => setNewsletterModalOpen(false)}
                onCancel={() => setNewsletterModalOpen(false)}
            >
                <TextArea readOnly rows={4} value={users.filter(user => user.newsletter).map((user, index) => `${index > 0 ? ';' : ''}${user.email}`).reduce((prev, curr) => prev+curr, '')}></TextArea>
            </Modal>
            <Modal
                title='Kleiderbörse bearbeiten'
                visible={editModalOpen}
                afterClose={() => setEditModalOpen(false)}
                onOk={() => setEditModalOpen(false)}
                onCancel={() => setEditModalOpen(false)}
                cancelButtonProps={{ hidden: true }}
                okButtonProps={{ hidden: true }}
            >
                <EditClothingShop
                    onDelete={() => {
                        setEditModalOpen(false);
                        push('/kleiderboersen');
                    }}
                    {...{ shopId: shop?.id, ...shop, postRegisterKey } as any}
                />
            </Modal>
        </PageHeader>
        <div className={classNames(appStyles['flex-grower'], appStyles['center-container'])}>
            {
                loading ?
                    <Spin tip='Lade Kleiderbörse...' />
                    :
                    !shop ?
                        <Result
                            title='Kleiderbörse nicht gefunden'
                            status='404'
                        />
                        :
                        <div className={classNames(appStyles['flex-grower'], appStyles['center-container-stretch'])}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                
                            </div>
                            <div>
                                <Table
                                    components={{
                                        body: {
                                            row: EditableFormRow,
                                            cell: EditableCell
                                        }
                                    }}
                                    tableLayout='fixed'
                                    size='small'
                                    columns={[
                                        numberColumn('', 'index'),
                                        {
                                            ...numberColumn('Verk. Nr.', 'sellerNumber'),
                                            onCell: (record) => ({
                                                record,
                                                editable: true,
                                                dataIndex: 'sellerNumber',
                                                title: 'Verk. Nr.',
                                                handleSave: updateSellerNumber
                                            })
                                        },
                                        stringColumn('Nachname', 'surename'),
                                        stringColumn('Vorname', 'prename'),
                                        stringColumn('Strasse', 'street'),
                                        stringColumn('Ort', 'city'),
                                        stringColumn('Handy', 'phone'),
                                        stringColumn('Email', 'email'),
                                        booleanColumn('Helfer', 'helper'),
                                        booleanColumn('Newsletter', 'newsletter')
                                    ]}
                                    dataSource={users.map((user, index) => ({
                                        id: user.id,
                                        index: index+1,
                                        sellerNumber: user.sellerNumber,
                                        surename: user.surename,
                                        prename: user.prename,
                                        street: user.street,
                                        city: user.city,
                                        phone: user.phone,
                                        email: user.email,
                                        helper: user.helper,
                                        seller: user.seller,
                                        newsletter: user.newsletter
                                    }))}
                                    rowKey={record => record.id}
                                    pagination={{ defaultPageSize: 100, position: 'both', showSizeChanger: true }}
                                />
                            </div>
                        </div>
            }
        </div>
    </div>
}

// Editable table cell, taken from ant.design docs

const EditableContext = React.createContext(undefined);

const EditableRow = ({ form, index, ...props }: any) => (
    <EditableContext.Provider value={form}>
        <tr {...props} />
    </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component<any, any> {
    state = {
        editing: false,
        loading: false
    };

    toggleEdit = () => {
        const editing = !this.state.editing;
        this.setState({ editing }, () => {
            if (editing) {
                (this as any).input.focus();
            }
        });
    };

    save = () => {
        this.setState({ loading: true })
        const { record, handleSave, dataIndex } = this.props;
        (this as any).form.validateFields(async (error: any, values: any) => {
            if (error && error[dataIndex]) {
                // do nothing
            } else {
                try {
                    await handleSave({ ...record, ...values });
                    this.toggleEdit();
                } catch(err) {
                    (this as any).form.setFields({
                        [dataIndex]: {
                            value: values[dataIndex], errors: [err]
                        }
                    })
                }
            }
            
            this.setState({ loading: false })
        });
    };

    reset = (dataIndex: string, initialValue: any) => {
        (this as any).form.setFields({
            [dataIndex]: {
                value: initialValue
            }
        })
        this.setState({ editing: false, loading: false })
    }

    renderCell = (form: any) => {
        (this as any).form = form;
        const { children, dataIndex, record } = this.props;
        const { editing } = this.state;
        return editing ? (
            <Form.Item style={{ margin: 0 }}>
                {form.getFieldDecorator(dataIndex, {
                    rules: [],
                    initialValue: record[dataIndex],
                })(
                    <Input disabled={this.state.loading} ref={node => ((this as any).input = node)} onKeyDown={e => e.key === 'Escape' && this.reset(dataIndex, record[dataIndex])} onPressEnter={this.save} />
                )}
                <Button.Group>
                    <Button size='default' type='danger' icon='rollback' disabled={this.state.loading} onClick={() => this.reset(dataIndex, record[dataIndex])}></Button>
                    <Button size='default' type='primary' icon='save' disabled={this.state.loading} onClick={() => this.save()}></Button>
                </Button.Group>
            </Form.Item>
        ) : (
                <div
                    className={classNames("editable-cell-value-wrap", styles['editable-cell'])}
                    style={{ paddingRight: 24, paddingLeft: 4, minHeight: '20px' }}
                    onClick={this.toggleEdit}
                >
                    {children}
                </div>
            );
    };

    render() {
        const {
            editable,
            dataIndex,
            title,
            record,
            index,
            handleSave,
            children,
            ...restProps
        } = this.props;
        return (
            <td {...restProps}>
                {editable && isSeller(record) ? (
                    <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
                ) : (
                        children
                    )}
            </td>
        );
    }
}