import React from 'react';
import ReactExport from 'react-data-export';
import { ClothingShopEvent } from '../clothing-shop';
import { User } from './clothing-shop-details';
import { Button } from 'antd';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


export interface ClothingShopExportProps {
    shop: ClothingShopEvent
    users: User[]
}

export const ClothingShopExcelExport = (props: ClothingShopExportProps) => {
    const { shop, users } = props;

    return <ExcelFile 
                filename={`Kleiderbörse_${shop.date ? `${shop.date.toDate().getDate()}_${shop.date.toDate().getMonth()+1}_${shop.date.toDate().getFullYear()}` : 'invalid_date'}`} 
                element={
                    <Button
                        icon='file-excel'
                        style={{ width: '100%' }}
                    >
                        Liste als Excel
                    </Button>
                }
            >
                <ExcelSheet data={users} name="Alle">
                    <ExcelColumn label="Verk.Nr." value="sellerNumber"/>
                    <ExcelColumn label="Nachname" value="surename"/>
                    <ExcelColumn label="Vorname" value="prename"/>
                    <ExcelColumn label="Strasse" value="street"/>
                    <ExcelColumn label="Ort" value="city"/>
                    <ExcelColumn label="Handy" value="phone"/>
                    <ExcelColumn label="Email" value="email"/>
                    <ExcelColumn 
                        label="Helfer"
                        value={(col: User) => col.helper ? 'Ja' : 'Nein'}
                    />
                    <ExcelColumn 
                        label="Verkäufer"
                        value={(col: User) => col.seller ? 'Ja' : 'Nein'}
                    />
                    <ExcelColumn 
                        label="Newsletter"
                        value={(col: User) => col.newsletter ? 'Ja' : 'Nein'}
                    />
                </ExcelSheet>
                <ExcelSheet data={users.filter(user => user.seller)} name="Verkäufer">
                    <ExcelColumn label="Verk.Nr." value="sellerNumber"/>
                    <ExcelColumn label="Nachname" value="surename"/>
                    <ExcelColumn label="Vorname" value="prename"/>
                    <ExcelColumn label="Strasse" value="street"/>
                    <ExcelColumn label="Ort" value="city"/>
                    <ExcelColumn label="Handy" value="phone"/>
                    <ExcelColumn label="Email" value="email"/>
                    <ExcelColumn 
                        label="Helfer"
                        value={(col: User) => col.helper ? 'Ja' : 'Nein'}
                    />
                    <ExcelColumn 
                        label="Verkäufer"
                        value={(col: User) => col.seller ? 'Ja' : 'Nein'}
                    />
                    <ExcelColumn 
                        label="Newsletter"
                        value={(col: User) => col.newsletter ? 'Ja' : 'Nein'}
                    />
                </ExcelSheet>
                <ExcelSheet data={users.filter(user => user.helper)} name="Helfer">
                    <ExcelColumn label="Verk.Nr." value="sellerNumber"/>
                    <ExcelColumn label="Nachname" value="surename"/>
                    <ExcelColumn label="Vorname" value="prename"/>
                    <ExcelColumn label="Strasse" value="street"/>
                    <ExcelColumn label="Ort" value="city"/>
                    <ExcelColumn label="Handy" value="phone"/>
                    <ExcelColumn label="Email" value="email"/>
                    <ExcelColumn 
                        label="Helfer"
                        value={(col: User) => col.helper ? 'Ja' : 'Nein'}
                    />
                    <ExcelColumn 
                        label="Verkäufer"
                        value={(col: User) => col.seller ? 'Ja' : 'Nein'}
                    />
                    <ExcelColumn 
                        label="Newsletter"
                        value={(col: User) => col.newsletter ? 'Ja' : 'Nein'}
                    />
                </ExcelSheet>
                <ExcelSheet data={users.filter(user => user.seller || user.helper)} name="Helfer und Verkäufer">
                    <ExcelColumn label="Verk.Nr." value="sellerNumber"/>
                    <ExcelColumn label="Nachname" value="surename"/>
                    <ExcelColumn label="Vorname" value="prename"/>
                    <ExcelColumn label="Strasse" value="street"/>
                    <ExcelColumn label="Ort" value="city"/>
                    <ExcelColumn label="Handy" value="phone"/>
                    <ExcelColumn label="Email" value="email"/>
                    <ExcelColumn 
                        label="Helfer"
                        value={(col: User) => col.helper ? 'Ja' : 'Nein'}
                    />
                    <ExcelColumn 
                        label="Verkäufer"
                        value={(col: User) => col.seller ? 'Ja' : 'Nein'}
                    />
                    <ExcelColumn 
                        label="Newsletter"
                        value={(col: User) => col.newsletter ? 'Ja' : 'Nein'}
                    />
                </ExcelSheet>
                <ExcelSheet data={users.filter(user => user.newsletter)} name="Newsletter">
                    <ExcelColumn label="Verk.Nr." value="sellerNumber"/>
                    <ExcelColumn label="Nachname" value="surename"/>
                    <ExcelColumn label="Vorname" value="prename"/>
                    <ExcelColumn label="Strasse" value="street"/>
                    <ExcelColumn label="Ort" value="city"/>
                    <ExcelColumn label="Handy" value="phone"/>
                    <ExcelColumn label="Email" value="email"/>
                    <ExcelColumn 
                        label="Helfer"
                        value={(col: User) => col.helper ? 'Ja' : 'Nein'}
                    />
                    <ExcelColumn 
                        label="Verkäufer"
                        value={(col: User) => col.seller ? 'Ja' : 'Nein'}
                    />
                    <ExcelColumn 
                        label="Newsletter"
                        value={(col: User) => col.newsletter ? 'Ja' : 'Nein'}
                    />
                </ExcelSheet>
            </ExcelFile>
}