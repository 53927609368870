import React, { useState } from 'react';
import classNames from 'classnames';
import appStyles from 'styles.module.scss';
import { Checkbox, Form, Input, Icon, Typography, Alert, Radio, Button } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { User } from 'components/clothing-shop/detail/clothing-shop-details';
import { ClothingShopEvent } from 'components/clothing-shop/clothing-shop';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { functions } from 'config/firebase/firebase';
import moment from 'moment';

export interface RegisterFormProps extends FormComponentProps, User {
    shop: ClothingShopEvent
    type: 'seller' | 'both'
    lateRegisterPassword?: string
}

interface RegistrationPayload extends User {
    recaptchaToken: string | undefined
    shopId: string
    lateRegisterPassword?: string
}

const RegisterFormImpl = (props: RegisterFormProps) => {
    const { shop, form } = props;

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState<{ message: string, type: 'success' | 'error' | 'warning' | 'info'} | null>(null)

    // const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        form.validateFields(async (err, values: RegisterFormProps) => {
            if(!err) {
                setLoading(true);

                const recaptchaToken = executeRecaptcha ? await executeRecaptcha() : undefined;

                const createdUser: RegistrationPayload = {
                    prename: values.prename,
                    surename: values.surename,
                    street: values.street,
                    city: values.city,
                    email: values.email,
                    phone: values.phone,
                    newsletter: !!values.newsletter,
                    helper: ['helper', 'both'].includes(values.type),
                    seller: ['seller', 'both'].includes(values.type),
                    id: undefined as any,
                    recaptchaToken: recaptchaToken,
                    shopId: props.shop.id,
                    lateRegisterPassword: props.lateRegisterPassword
                }

                try {
                    await functions().httpsCallable('registerSeller')({
                        ...createdUser
                    })
                    setMessage({ message: 'Erfolgreich registriert.', type: 'success' });
                } catch(err) {
                    setMessage({ message: (err.message === 'INTERNAL' ? undefined : err.message) || 'Ein unerwarteter Fehler ist aufgetreten.', type: 'error' });
                }

                if(window.scrollTo) window.scrollTo(0, 0);

                setLoading(false);
            }
        })
    }

    const { getFieldDecorator } = form;

    const textField = (key: string, label: string, placeholder: string, icon: string | false, required?: boolean) => <Form.Item label={label}>
        {
            getFieldDecorator(key, {
                rules: required ? [{ required: true, message: 'Dieses Feld ist verlangt.' }] : []
            })(
                <Input
                    prefix={!!icon ? <Icon type={icon} style={{ color: 'rgba(0,0,0,.25)' }} /> : undefined}
                    placeholder={placeholder}
                    size='default'
                    disabled={loading}
                />
            )
        }
    </Form.Item>

    return <div className={classNames(appStyles['flex-grower'], appStyles['center-container-stretch'])}>
        <Typography.Title level={3}>Kinderkleiderbörse { !!shop.date ? moment(shop.date?.toDate()).format('l') : 'Unbekanntest Datum' }</Typography.Title>
        <Form onSubmit={handleSubmit}>
            { !!message && <Alert {...message} showIcon style={{ marginBottom: '14px' }} /> }
            {
                props.lateRegisterPassword ? 
                <Alert 
                    showIcon
                    message={'Da Sie ein Spätanmeldungspasswort besitzen, können Sie sich anmelden'}
                />
                :
                <Alert 
                    showIcon
                    message={'Bitte beachten Sie, dass die Reservation wie folgt möglich ist:'}
                    description={<ul>
                        <li>für HelferInnen ab dem <b>{shop.registerHelper ? moment(shop.registerHelper?.toDate()).format('l') : 'Nicht definiert'}</b>.</li>
                        <li>für alle anderen VerkäuferInnen ab dem <b>{shop.registerSeller ? moment(shop.registerSeller?.toDate()).format('l') : 'Nicht definiert'}</b>.</li>    
                    </ul>}
                />
            }
            <Form.Item label='Ich bin'>
                {
                    getFieldDecorator('type', {
                        rules: [
                            { required: true, message: 'Dieses Feld ist verlangt.' },
                            {
                                validator: (rule, value, callback) => {
                                    const shopDate = shop.date ? shop.date.toDate() : new Date();
                                    const sellerDate: Date = shop.registerSeller ? shop.registerSeller.toDate() : shopDate;
                                    const helperDate: Date = shop.registerHelper ? shop.registerHelper.toDate() : shopDate;
                                    const sellerDisallowed = sellerDate > new Date();
                                    const helperDisallowed = helperDate > new Date();
                                    
                                    let allowed = true;
                                    switch(value) {
                                        case 'both':
                                            if(helperDisallowed) allowed = false;
                                            break;
                                        default:
                                            if(helperDisallowed || sellerDisallowed) allowed = false;
                                    }

                                    if(allowed) {
                                        callback();
                                    } else {
                                        callback('Für diese Funktion kannst du dich zurzeit nicht registrieren.')
                                    }
                                }
                            }
                        ]
                    })(
                        <Radio.Group buttonStyle='solid' disabled={loading}>
                            <Radio value='seller'>Verkäufer</Radio>
                            <Radio value='both'>Verkäufer und Helfer</Radio>
                        </Radio.Group>
                    )
                }
            </Form.Item>
            {textField('prename', 'Vorname', 'Max', 'user', true)}
            {textField('surename', 'Nachname', 'Muster', 'user', true)}
            {textField('street', 'Strasse', 'Musterstrasse 5', 'car', true)}
            {textField('city', 'Plz & Ort', 'Urdorf', 'home', true)}
            {textField('email', 'Email', 'max@muster.ch', 'mail', true)}
            {textField('phone', 'Telefon', 'Telefon', 'phone', true)}
            <Form.Item label='Ich möchte den Newsletter erhalten'>
                {
                    getFieldDecorator('newsletter', {
                        rules: []
                    })(
                        <Checkbox
                            disabled={loading}
                        />
                    )
                }
            </Form.Item>
            <p>Der Familien-Verein Urdorf übernimmt keine Haftung, sowohl für Beschädigungen oder Funktionsstörungen des gekauften Gegenstandes, als auch für Folgeschäden jeder Art, welche durch den Gebrauch des Gegenstandes entstehen können. Eine Rücknahme oder Umtausch kann nicht gewährt werden. In einem Reklamationsfall werden die Kontaktdaten der VerkäuferInnen an die KäuferInnen ausgehändigt.</p>
            <Form.Item label='Hiermit erkläre ich, dass ich den Haftungsausschluss vollständig gelesen habe und mit deren Inhalt einverstanden bin.'>
                {
                    getFieldDecorator('disclaimer', {
                        rules: [ { required: true, message: 'Dieses Feld ist obligatorisch.' },]
                    })(
                        <Checkbox
                            disabled={loading}
                        />
                    )
                }
            </Form.Item>
            <Form.Item>
                <Button type='primary' size='large' htmlType='submit' block loading={loading}>
                    Registrieren
                </Button>
            </Form.Item>
        </Form>
    </div>
}

export const RegisterForm = Form.create<RegisterFormProps>()(RegisterFormImpl);