import React from 'react';
import { Icon } from 'antd';
import { HomeView } from 'components/home/home';
import { LoginView } from 'components/login/login';
import { ClothingShopView } from 'components/clothing-shop/clothing-shop';
import { RegisterView } from 'components/register/register';
import { PrivacyView } from 'components/privacy/privacy';

export interface AppRoute {
    key: string
    displayName: string
    icon: JSX.Element
    // is this route available when the user is *not* logged in
    public?: boolean
    // is this route available when the user *is* logged in
    private?: boolean
    // if this route is available when signed in, does it require the user to be admin?
    adminOnly?: boolean
    // Do not include this route in the sidebar?
    view: React.FC
}

export const HomeRoute: AppRoute = {
    key: '/',
    displayName: 'Home',
    icon: <Icon type='home' />,
    view: HomeView
}

export const AppRoutes: AppRoute[] = [
    {
        key: '/register',
        displayName: 'Registrieren',
        icon: <Icon type='user' />,
        private: true,
        public: true,
        view: RegisterView
    },
    {
        key: '/login',
        displayName: 'Admin Dashboard',
        icon: <Icon type='setting' />,
        public: true,
        view: LoginView
    },
    {
        key: '/kleiderboersen',
        displayName: 'Kinderkleiderbörsen',
        icon: <Icon type='skin' />,
        private: true,
        adminOnly: true,
        view: ClothingShopView
    },
    {
        key: '/privacy',
        displayName: 'Datenschutz',
        icon: <Icon type='lock' />,
        private: true,
        public: true,
        view: PrivacyView
    }
]