import React from 'react';
import classNames from 'classnames';
import appStyles from 'styles.module.scss';
import { useHistory } from 'react-router';
import { Form, PageHeader } from 'antd';

import { EditClothingShop } from '../edit/edit-clothing-shop';


const CreateClothingShopImpl = () => {
    const { push } = useHistory();

    return <div className={classNames(appStyles['flex-grower'], appStyles['center-container-stretch'])}>
        <PageHeader title='Kinderkleiderbörsen' subTitle={`Kleiderbörse erstellen`} onBack={() => push('/kleiderboersen')}></PageHeader>
        <div className={classNames(appStyles['flex-grower'], appStyles['center-container'])}>
            <EditClothingShop
            />
        </div>
    </div>
}

export const CreateClothingShop = Form.create()(CreateClothingShopImpl);